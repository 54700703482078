import React, { useEffect } from 'react';

import { Layout } from '@/components/layout';
import { LeftPanel } from '@/components/left-panel';
import { RightChatPanel } from '@/components/right-chat-panel';
import { ChatControlsProvider } from '@/contexts/chat-controls';
import { useDragDetection } from '@/hooks/use-drag-detection';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { useQueryExample } from '@/store/hooks/use-query-example';

export function LoggedOutChatScreen() {
    const { hasDragging, ...dragListeners } = useDragDetection();
    const { chat } = useLoggedOutChat();
    useQueryExample();
    return (
        <Layout pageTitle={'Reka Playground'} {...dragListeners}>
            <ChatControlsProvider showDropZones={hasDragging} id={null}>
                <LeftPanel />
                <RightChatPanel chat={chat} />
            </ChatControlsProvider>
        </Layout>
    );
}
